import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

import * as _shim2 from "use-sync-external-store/shim";

var _shim = "default" in _shim2 ? _shim2.default : _shim2;

var exports = {};
var h = _react,
    n = _shim;

function p(a, b) {
  return a === b && (0 !== a || 1 / a === 1 / b) || a !== a && b !== b;
}

var q = "function" === typeof Object.is ? Object.is : p,
    r = n.useSyncExternalStore,
    t = h.useRef,
    u = h.useEffect,
    v = h.useMemo,
    w = h.useDebugValue;

exports.useSyncExternalStoreWithSelector = function (a, b, e, l, g) {
  var c = t(null);

  if (null === c.current) {
    var f = {
      hasValue: !1,
      value: null
    };
    c.current = f;
  } else f = c.current;

  c = v(function () {
    function a(a) {
      if (!c) {
        c = !0;
        d = a;
        a = l(a);

        if (void 0 !== g && f.hasValue) {
          var b = f.value;
          if (g(b, a)) return k = b;
        }

        return k = a;
      }

      b = k;
      if (q(d, a)) return b;
      var e = l(a);
      if (void 0 !== g && g(b, e)) return b;
      d = a;
      return k = e;
    }

    var c = !1,
        d,
        k,
        m = void 0 === e ? null : e;
    return [function () {
      return a(b());
    }, null === m ? void 0 : function () {
      return a(m());
    }];
  }, [b, e, l, g]);
  var d = r(a, c[0], c[1]);
  u(function () {
    f.hasValue = !0;
    f.value = d;
  }, [d]);
  w(d);
  return d;
};

export default exports;